import React from 'react';
import styled from '@emotion/styled';
import { ThemeProvider } from '@emotion/react';

import { Flex, Box } from 'components/ReflexBox';
import Header from 'components/Header';
import Footer from 'components/Footer';
import themes from 'themes';

import NormalizeStyle from 'themes/normalize';
import GlobalStyle from 'themes/global';
import MetaTags from './MetaTags';
import { getThemeColor } from 'utils/css-utils';

const PageWrapper = styled(Flex)`
  color: ${getThemeColor('text')};
  width: 100%;
  position: relative;
  flex-direction: column;
  height: 100vh;
  position: relative;
`;

const PageContent = styled(Box)`
  flex: 1 0 auto;
`;

const Banner = styled(Box)`
  text-align: center;
  background: #eee;
  color: #777;
  font-size: 13px;
  padding: 3px 0;
`;

const Page = ({
  children,
  theme = 'dark',
  metaTags,
  showBanner = false,
  ...rest
}) => {
  const pageTheme = themes[theme];

  return (
    <ThemeProvider theme={pageTheme}>
      <MetaTags {...metaTags} />
      <NormalizeStyle />
      <GlobalStyle />
      <PageWrapper>
        {showBanner && (
          <Banner>
            <strong>webkid is not taking on new projects right now</strong>{' '}
            &ndash; we are currently focused on developing{' '}
            <a href="https://reactflow.dev" target="_blank">
              React Flow
            </a>
            , a library for building interactive node-based UIs.
          </Banner>
        )}
        <Header />
        <PageContent {...rest}>{children}</PageContent>
        <Footer />
      </PageWrapper>
    </ThemeProvider>
  );
};

export default Page;
