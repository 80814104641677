import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { Flex, Box } from 'components/ReflexBox';

import Icon from 'components/Icon';
import Logo from 'components/Logo';
import CenterContent from 'components/CenterContent';
import Button from 'components/Button';
import { getThemeColor, getThemeSpacePx, device, px } from 'utils/css-utils';

const Centered = styled(CenterContent)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: ${getThemeSpacePx(4)};
`;

const Wrapper = styled.nav`
  flex-shrink: 0;
`;

const NavWrapper = styled(Box)`
  position: absolute;
  display: ${(p) => (p.menuOpen ? 'flex' : 'none')};
  height: ${(p) => px(p.height)};
  top: 0;
  left: 0;
  width: 100%;
  padding: ${getThemeSpacePx(3)};
  z-index: 100;
  background: ${getThemeColor('background')};
  align-items: center;
  flex-direction: column;

  .desktop {
    display: none;
  }

  @media ${device.tablet} {
    display: block;
    height: auto;
    position: relative;
    padding: 0;
    margin-left: auto;
    width: auto;
    background: transparent;
    align-items: unset;
    flex-direction: row;

    .desktop {
      display: inline-block;
    }

    .mobile {
      display: none;
    }
  }
`;

const Nav = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

const NavInner = styled.div`
  line-height: 1;
  width: 100%;

  @media ${device.tablet} {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: auto;
  }
`;

const NavItem = styled(Link)`
  text-decoration: none;
  font-size: 30px;
  display: block;
  padding: ${(p) => (p.isButton ? '8px 16px' : '16px 0')};
  text-align: center;
  color: ${getThemeColor('textLight')};

  &:focus,
  &:visited,
  &:active {
    color: ${getThemeColor('textLight')};
  }

  &.active {
    color: ${getThemeColor('text')};
  }

  @media ${device.tablet} {
    margin-left: 50px;
    font-size: 16px;
    display: inline-block;
    padding: ${(p) => (p.isButton ? '8px 16px' : 0)};
    color: ${getThemeColor('textLight')};

    &:focus,
    &:visited {
      color: ${getThemeColor('textLight')};
    }

    &.active,
    &:hover {
      color: ${getThemeColor('text')};
    }
  }
`;

const Close = styled.div`
  padding: ${getThemeSpacePx(1)};
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
`;

const NavButton = styled.div`
  cursor: pointer;

  @media ${device.tablet} {
    display: none;
  }
`;

const Header = () => {
  const [menuHeight, setMenuHeight] = useState(
    typeof window !== 'undefined' ? window.innerHeight : 0
  );
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    const nextMenuOpen = !menuOpen;

    document.body.classList.toggle('noscroll', nextMenuOpen);

    setMenuOpen(nextMenuOpen);
  };

  useEffect(() => {
    const onResize = () => {
      setMenuHeight(typeof window !== 'undefined' ? window.innerHeight : 0);
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  });

  return (
    <Wrapper>
      <Centered>
        <Link to="/">
          <Logo />
        </Link>
        <NavButton onClick={toggleMenu}>
          <Icon name="menu" strokeColor="text" colorizeStroke />
        </NavButton>
        <NavWrapper menuOpen={menuOpen} height={menuHeight}>
          <Nav>
            <NavInner>
              <NavItem to="/" activeClassName="active" className="mobile">
                Home
              </NavItem>
              <NavItem to="/services" activeClassName="active">
                Services
              </NavItem>
              <NavItem to="/portfolio" activeClassName="active">
                Portfolio
              </NavItem>
              <NavItem to="/about" activeClassName="active">
                About
              </NavItem>
              <NavItem to="/blog" activeClassName="active">
                Blog
              </NavItem>

              <Button
                as={NavItem}
                to="/contact"
                activeClassName="active"
                icon="mail"
                className="desktop"
                isButton
              >
                Contact us
              </Button>

              <NavItem
                to="/contact"
                activeClassName="active"
                className="mobile"
                isButton
              >
                Contact us
              </NavItem>
            </NavInner>
          </Nav>
          <Flex pb={4} alignItems="center" className="mobile">
            <Close onClick={toggleMenu}>
              <Icon size={50} name="close" strokeColor="text" colorizeStroke />
            </Close>
          </Flex>
        </NavWrapper>
      </Centered>
    </Wrapper>
  );
};

export default Header;
