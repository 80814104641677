import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { Box, Flex } from 'components/ReflexBox';

import Icon from 'components/Icon';
import Logo from 'components/Logo';
import CenterContent from 'components/CenterContent';
import { Text } from 'components/Typo';
import { device, getThemeColor } from 'utils/css-utils';

const Wrapper = styled.footer`
  padding: 50px 0;
  background: ${getThemeColor('footerBackground')};
  flex-shrink: 0;
`;

const Centered = styled(CenterContent)`
  display: flex;
  flex-direction: column;

  & > a {
    margin-bottom: 10px;
  }

  @media ${device.tablet} {
    flex-direction: row;

    & > a {
      margin-bottom: 0;
    }
  }
`;

const StyledLink = styled(Link)`
  &:hover {
    div {
      opacity: 0.6;
    }
  }
`;

const Header = () => {
  return (
    <Wrapper>
      <Centered>
        <Link to="/">
          <Logo inverted />
        </Link>

        <Flex width={1} flexWrap="wrap">
          <Box ml={[0, 0, '100px']}>
            <Text color="textInverted">
              webkid GmbH
              <br />
              Kohlfurter Straße 41/43
              <br />
              10999 Berlin
            </Text>
          </Box>

          <Box display={['none', 'none', 'block']} ml={[2, '100px']}>
            <Text color="textInverted">
              info@webkid.io
              <br />
              +49 30 232 575 450
            </Text>
          </Box>

          <Box ml={[2, '100px']}>
            <StyledLink to="/imprint">
              <Text color="textInverted">Imprint</Text>
            </StyledLink>
            <StyledLink to="/privacy">
              <Text color="textInverted">Privacy</Text>
            </StyledLink>
          </Box>

          <Box ml="auto">
            <Icon
              as="a"
              href="https://github.com/wbkd"
              name="github_circle"
              colorizeStroke
              strokeColor="textInverted"
              style={{ marginBottom: 8, display: 'block' }}
              size={20}
            />
            <Icon
              as="a"
              href="https://twitter.com/webk1d"
              name="twitter_circle"
              colorizeStroke
              strokeColor="textInverted"
              size={20}
            />
          </Box>
        </Flex>
      </Centered>
    </Wrapper>
  );
};

export default Header;
