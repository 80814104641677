import React from 'react';
import { Helmet } from 'react-helmet';

const MetaTags = ({
  title,
  twitterUsername = '@wbkd',
  description,
  siteUrl,
  robots,
  image,
  pathname,
  article,
  isBlog = false,
}) => {
  if (pathname) {
    siteUrl = `${siteUrl}${pathname}`;
  }

  if (image) {
    image = `https://webkid.io${image}`;
  } else {
    image = 'https://webkid.io/images/webkid-social.jpg';
  }

  const fathomId = isBlog ? 'JVTXOICW' : 'RYLYBDFT';

  return (
    <Helmet defaultTitle="webkid">
      <html lang="en" />
      <title>{title}</title>
      {description && <meta name="description" content={description} />}
      {description && <meta name="robots" content={robots} />}

      {siteUrl && <meta property="og:url" content={siteUrl} />}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={image} />}

      <meta name="twitter:card" content="summary_large_image" />
      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}
      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="twitter:description" content={description} />}
      {image && <meta name="twitter:image" content={image} />}

      <script
        src="https://cdn.usefathom.com/script.js"
        site={fathomId}
        defer
      ></script>
    </Helmet>
  );
};

export default MetaTags;
